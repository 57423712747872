import styled from 'styled-components';

import { Page, Title } from 'pages';

const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

const notfound = () => (
  <Page>
    <Title>404</Title>
    <Subtitle>Not found</Subtitle>
  </Page>
);

export default notfound;
