import { Link, GatsbyLinkProps } from 'gatsby';
import styled from 'styled-components';

export const Page = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.secondary};
  background: ${({ theme }) => theme.backgrounds.normal};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
`;

const Social = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const IsomorphicLink = <T,>({
  to,
  ...props
}: Omit<GatsbyLinkProps<T>, 'ref'>) => {
  if (to.startsWith('http')) return <a href={to} {...props} />;

  return <Link to={to} {...props} />;
};

const SocialLink = styled(IsomorphicLink)`
  position: relative;
  margin-bottom: 0.75rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.thirdary};
  transition: color 0.2s;

  &::last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.1rem;
    height: 1px;
    background: currentColor;
    max-width: 0;
    transition: max-width ease 0.2s;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};

    &::before {
      max-width: 100%;
    }
  }
`;

const index = () => (
  <Page>
    <Title>rqbik</Title>
    <Social>
      <SocialLink to="https://github.com/rqbik">-&gt; GitHub</SocialLink>
      <SocialLink to="https://gitlab.com/rqbik">-&gt; GitLab</SocialLink>
    </Social>
  </Page>
);

export default index;
